<template>
  <base-loader v-if="loading"></base-loader>

  <div v-if="errorMsgLoading">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div class="row" v-if="auctionOrders">
    <div
      class="col-xl-4 mb-4"
      v-for="(order, index) in auctionOrders"
      :key="index"
    >
      <div class="card card-profile shadow order-card">
        <div class="card-body">
          <div class="d-flex justify-content-between mb-4">
            <span class="badge badge-info">
              {{ $t("auction.order info") }}
            </span>
          </div>
          <img
            :src="
              'https://nqal.co/LiveNqal/public/' +
              order.OrderRequestsItems[0]['image']
            "
            class="w-100"
            :alt="order.OrderRequestsItems[0]['title']"
          />
          <div>
            <h3 class="mt-2 mb-0">
              {{ $t("auction.order") }}:
              <span>{{ order.OrderRequestsItems[0].title }}</span>
            </h3>
            <h3 class="mb-0">
              {{ $t("auction.description") }}:
              <span>{{ order.OrderRequestsItems[0].description }}</span>
            </h3>
            <h3>
              {{ $t("auction.value") }}:
              <span>{{ order.OrderRequestsItems[0].value }}</span>
            </h3>
            <h3>
              {{ $t("auction.price") }}:
              <span v-if="order.is_paid == 0">{{ order.item_price }}</span>
              <span v-else>{{ $t("auction.already paid") }}</span>
            </h3>
            <h3>
              {{ $t("auction.type") }}: <span>{{ order.order_type }}</span>
            </h3>
            <h3>
              {{ $t("auction.package size") }}:
              <span>{{ order.pack_size }}</span>
            </h3>
          </div>
        </div>
        <hr class="m-0" />
        <div class="card-body">
          <div class="d-flex justify-content-between mb-4">
            <span
              class="badge badge-success order-dis"
              v-if="order.distance <= 20"
              >{{ $t("global.inside city") }}: {{ order.distance }} KM</span
            >
            <span class="badge badge-danger order-dis" v-else
              >{{ $t("global.outside city") }}: {{ order.distance }} KM</span
            >
          </div>
          <div>
            <h3>
              {{ $t("global.from") }}: <span>{{ order.address_from }}</span>
            </h3>
            <h3>
              {{ $t("global.to") }}: <span>{{ order.address_to }}</span>
            </h3>
            <hr />
            <div v-if="order.delivery_date">
              <h3>
                {{ $t("auction.delivery day") }}:
                <span>{{ order.delivery_date.split(" ")[0] }}</span>
              </h3>
              <h3>
                {{ $t("global.from") }}:
                <span>{{ order.timeSlot.from_time }}</span>
                <br />
                {{ $t("global.to") }}:
                <span>{{ order.timeSlot.to_time }}</span>
              </h3>
            </div>
            <div v-else-if="order.now">
              <h3>
                {{ $t("auction.delivery day") }}:
                <span>{{ $t("orders.now") }}</span>
              </h3>
            </div>
            <div v-else-if="order.twenty_four_hour">
              <h3>
                {{ $t("auction.delivery day") }}:
                <span>{{ $t("auction.through_twenty_four_hour") }}</span>
              </h3>
            </div>
          </div>
        </div>
        <hr class="m-0" />
        <div class="card-body">
          <div>
            <h3>
              {{ $t("global.payment method") }}:
              <span v-if="(order.payment_methods_id = 1)">
                {{ $t("auction.cash or qr") }}
              </span>
              <span v-else>{{ $t("auction.your wallet") }}:</span>
            </h3>
          </div>
        </div>
        <hr class="m-0" />
        <div class="card-body">
          <router-link :to="`/set-price/${order.id}`" class="btn btn-primary">
            {{ $t("auction.set price") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <!-- Pagination Component -->
  <base-pagination
    v-if="auctionOrders"
    :pagination="pagination"
    :paginationLinks="paginationLinks"
    v-on:pagination-prev="getAuctionOrders($event)"
    v-on:pagination-num="getAuctionOrders(PaginationNum + $event)"
    v-on:pagination-next="getAuctionOrders($event)"
  />
</template>

<script>
import axios from "axios";
export default {
  name: "auction-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      errorMsgLoading: false,
      auctionOrders: "",
      delivery_date: "",
      pagination: "",
      paginationLinks: "",
      // For Pagination num
      PaginationNum: "",
    };
  },
  created() {
    this.getAuctionOrders("companies/requestedorders/index?page=1");
  },
  methods: {
    getAuctionOrders: function ($pagination) {
      this.loading = true;
      this.errorMsgLoading = false;
      this.errorMsg = false;
      axios
        .get($pagination, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            if (response.data.status == 0) {
              this.errorMsg = [response.data.message];
            } else {
              this.auctionOrders = response.data.data;
            }
            this.pagination = response.data.meta;
            this.paginationLinks = response.data.links;
            this.PaginationNum = "companies/requestedorders/index?page=";
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.headers);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.card-body img {
  max-height: 150px;
  object-fit: contain;
}
.order-card h3 span {
  font-size: 14px;
  color: #444;
}
.order-dis {
  font-size: 13px;
}
</style>
