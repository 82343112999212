<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <div v-if="errorMsgLoading">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsgLoading"
              :key="index"
            >
              {{ error }}
            </div>
          </div>

          <div v-if="errorMsg">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsg"
              :key="index"
            >
              {{ error }}
            </div>
          </div>
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("users.new user") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("users.user information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row" v-if="validationErrors.length">
                  <div
                    class="col-md-6"
                    v-for="(error, index) in validationErrors"
                    :key="index"
                  >
                    <span class="bg-danger text-white mr-2 error-handling">{{
                      error
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      input-classes="form-control-alternative"
                      v-model="new_user.name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="email"
                      alternative=""
                      :label="$t('form.email')"
                      :placeholder="$t('form.email')"
                      input-classes="form-control-alternative"
                      v-model="new_user.email"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.phone')"
                      :placeholder="$t('form.phone')"
                      input-classes="form-control-alternative"
                      v-model="new_user.phone"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="password"
                      alternative=""
                      :label="$t('form.password')"
                      :placeholder="$t('form.password')"
                      input-classes="form-control-alternative"
                      v-model="new_user.password"
                    />
                  </div>
                  <!-- <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.role")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="new_user.role"
                      >
                        <option value="" selected>
                          {{ $t("form.choose role") }}
                        </option>
                        <option
                          v-for="(role, index) in roles"
                          :key="index"
                          :value="role.id"
                        >
                          {{ role.name }}
                        </option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.gender")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="new_user.gender"
                      >
                        <option value="" selected>
                          {{ $t("form.choose gender") }}
                        </option>
                        <option value="male">
                          {{ $t("form.male") }}
                        </option>
                        <option value="female">
                          {{ $t("form.female") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("profile.image") }}
                      </label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="updateImage"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.add')"
                @click.prevent="addUser"
              />
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "new-user",
  data() {
    return {
      loading: false,
      errorMsgLoading: false,
      errorMsg: "",
      new_user: {
        name: "",
        email: "",
        phone: "",
        password: "",
        // role: "",
        gender: "",
        avatar: "",
      },
      // roles: [],
      // Validation
      validationErrors: [],
    };
  },
  created() {
    // this.getRoles("companies/members/roles/index");
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (this.new_user.name) {
        return true;
      } else {
        return false;
      }
    },
    // Add User
    addUser: function () {
      if (this.validation() == false) {
        return false;
      } else {
        this.errorMsgLoading = "";
        this.errorMsg = "";
        this.loading = true;
        let formData = this.toFormData(this.new_user);
        const headers = {
          "Accept-Language": "en",
        };
        axios
          .post("companies/members/register", formData, { headers: headers })
          .then((response) => {
            if (response.data.error) {
              this.errorMsg = response.data.message;
            } else {
              if (response.data.status == 0) {
                this.errorMsg = [response.data.message];
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                this.$router.push({
                  name: "users",
                });
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 3000,
                });
              }
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                // console.log(error.response.data);
              } else if (error.request) {
                // console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
            } else {
              this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // Get All Roles
    // getRoles: function ($url) {
    //   this.loading = true;
    //   axios
    //     .get($url, {
    //       headers: {
    //         "Accept-Language": "en",
    //       },
    //     })
    //     .then((response) => {
    //       if (response.data.error) {
    //         //
    //       } else {
    //         this.roles = response.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       if (window.navigator.onLine) {
    //         if (error.response) {
    //           //
    //         } else if (error.request) {
    //           //
    //         } else {
    //           //
    //           //
    //         }
    //       } else {
    //         this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
    //       }
    //     })
    //     .finally(() => (this.loading = false));
    // },
    // Get Image to Update
    updateImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.new_user.avatar = files[0];
    },
    toFormData: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },
};
</script>
