<template>
  <div>
    <select
      class="btn btn-primary btn-sm"
      v-model="$i18n.locale"
      @change="storeLangInLocalStorage($event)"
    >
      <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
        <span v-if="lang == 'ar'"> Arabic </span>
        <span v-else> English </span>
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SwitchLocale",
  data() {
    return { langs: ["en", "ar"] };
  },
  methods: {
    storeLangInLocalStorage: function () {
      localStorage.setItem("userLang", event.target.value);
      window.location.reload();
    },
  },
};
</script>
