// import Vue from 'vue'
// import Vuex from 'vuex'

import User from "./modules/user";

// Vue.use(Vuex)
// export default new Vuex.Store({
//     modules: {
//         user: User
//     }
// })

import { createStore } from "vuex";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: User,
  },
});
