<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{ $t("sidebar.generate company report") }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("reports.date information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('global.from')"
                      :placeholder="$t('global.from')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.from_date"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('global.to')"
                      :placeholder="$t('global.from')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.to_date"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('reports.generate report')"
                @click.prevent="sendDatestoGenerate"
              />
            </form>
          </card>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <company-total-table
            type="dark"
            :title="$t('tables.total report')"
          ></company-total-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyTotalTable from "../../Tables/Reports/CompanyTotalTable.vue";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "Generate-Companies-Report",
  components: {
    CompanyTotalTable,
  },
  data() {
    return {
      model: {
        from_date: "",
        to_date: "",
      },
    };
  },
  methods: {
    // Send Date to get Report
    sendDatestoGenerate: function () {
      if (this.model.from_date.length > 0 && this.model.to_date.length > 0) {
        this.$router.push({
          name: "get companies report",
          params: { dates: [this.model.from_date, this.model.to_date] },
        });
      } else {
        return createToast(`${this.$t("validation.all fields required")}`, {
          type: "danger",
          timeout: 4000,
        });
      }
    },
  },
};
</script>
