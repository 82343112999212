<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <div v-if="errorMsgLoading">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsgLoading"
              :key="index"
            >
              {{ error }}
            </div>
          </div>

          <div v-if="errorMsg">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsg"
              :key="index"
            >
              {{ error }}
            </div>
          </div>
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("users.new role") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("users.role information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row" v-if="validationErrors.length">
                  <div
                    class="col-md-6"
                    v-for="(error, index) in validationErrors"
                    :key="index"
                  >
                    <span class="bg-danger text-white mr-2 error-handling">{{
                      error
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      input-classes="form-control-alternative"
                      v-model="new_role.role_name"
                    />
                  </div>
                </div>
                <hr class="my-4" />
                <div class="form-group has-label">
                  <label class="form-control-label d-block">
                    {{ $t("form.permissions") }}
                  </label>
                  <div class="row">
                    <div
                      class="col-lg-6"
                      v-for="(permission, index) in all_permissions"
                      :key="index"
                    >
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :id="'id_' + index"
                          :value="permission.id"
                          v-model="new_role.permissions"
                        />
                        <label
                          class="form-check-label mx-2"
                          :for="'id_' + index"
                        >
                          {{ permission.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.add')"
                @click.prevent="addRole"
              />
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "new-role",
  data() {
    return {
      loading: false,
      errorMsgLoading: false,
      errorMsg: "",
      new_role: {
        role_name: "",
        permissions: [],
      },
      all_permissions: [],
      // Validation
      validationErrors: [],
    };
  },
  created() {
    this.getPermissions();
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (this.new_role.role_name) {
        return true;
      } else {
        return false;
      }
    },
    // Add Role
    addRole: function () {
      if (this.validation() == false) {
        return false;
      } else {
        this.errorMsgLoading = "";
        this.errorMsg = "";
        this.loading = true;
        let formData = this.toFormData();
        const headers = {
          "Accept-Language": "en",
        };
        axios
          .post("companies/members/roles/store", formData, { headers: headers })
          .then((response) => {
            if (response.data.error) {
              this.errorMsg = response.data.message;
            } else {
              if (response.data.status == 0) {
                this.errorMsg = [response.data.message];
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                this.$router.push({
                  name: "roles",
                });
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 3000,
                });
              }
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                // console.log(error.response.data);
              } else if (error.request) {
                // console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
            } else {
              this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // Get All Permissions
    getPermissions: function () {
      this.loading = true;
      axios
        .get("companies/members/permissions/index", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            this.all_permissions = response.data.data;
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Form Fields
    toFormData: function () {
      var fd = new FormData();
      fd.append("role_name", this.new_role.role_name);
      for (var permission in this.new_role.permissions) {
        fd.append(
          "permissions[" + permission + "][id]",
          this.new_role.permissions[permission]
        );
      }
      return fd;
    },
  },
};
</script>
