<template>
  <base-loader v-if="loading"></base-loader>
  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div class="table-responsive">
      <table
        class="table tablesorter table align-items-center table-flush table-dark"
      >
        <!-- Table Head -->
        <thead class="thead-dark">
          <th>{{ $t("tables.plan") }}</th>
          <th>{{ $t("wallet.amount") }}</th>
          <th>{{ $t("wallet.charge") }}</th>
          <th>{{ $t("tables.orders count") }}</th>
        </thead>
        <tbody>
          <tr>
            <td>Premium</td>
            <td>250 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(250)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 25 {{ $t("wallet.orders") }} </span>
            </td>
          </tr>
          <tr>
            <td>Gold</td>
            <td>200 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(200)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 20 {{ $t("wallet.orders") }} </span>
            </td>
          </tr>
          <tr>
            <td>Silver</td>
            <td>150 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(150)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 15 {{ $t("wallet.orders") }} </span>
            </td>
          </tr>
          <tr>
            <td>Bronze</td>
            <td>100 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(100)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 10 {{ $t("wallet.orders") }} </span>
            </td>
          </tr>
          <tr>
            <td>Normal</td>
            <td>50 {{ $t("wallet.aed") }}</td>
            <td>
              <button @click="chargeWallet(50)" class="btn btn-sm btn-success">
                {{ $t("wallet.charge") }}
              </button>
            </td>
            <td>
              <span class="text-white"> 5 {{ $t("wallet.orders") }} </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "charge-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      paymentUrl: "",
      companyId: "",
      companyEmail: "",
      companyName: "",
    };
  },
  created() {
    this.companyId = JSON.parse(
      localStorage.getItem("nqalCompanyLoginData")
    ).company_id;
    this.companyEmail = JSON.parse(
      localStorage.getItem("nqalCompanyLoginData")
    ).email;
    this.companyName = JSON.parse(
      localStorage.getItem("nqalCompanyLoginData")
    ).name;
  },
  methods: {
    chargeWallet: function ($amount) {
      const params = {
        amount: $amount,
        name: this.companyName,
        email: this.companyEmail,
        id: this.companyId,
        type: "company",
      };
      this.loading = true;
      axios
        .post("https://nqal.co/LiveNqal/api/wallet/charge", params, {
          headers: {
            "Content-Type": "application/json",
            "x-api-password": "df3djdk322323dsd",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            this.paymentUrl = response.data.data;
            window.open(this.paymentUrl, "_blank");
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
