<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="min-height: 300px"
    >
      <!-- Mask -->
      <span class="mask bg-gradient-main opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row w-100">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">{{ trader.name }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <base-loader v-if="loading"></base-loader>
      <div v-if="errorMsg">
        <div
          class="alert alert-danger text-center"
          v-for="(error, index) in errorMsg"
          :key="index"
        >
          {{ error }}
        </div>
      </div>
      <div v-else class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      src="../../assets/trader.jpg"
                      class="rounded-circle"
                      alt="Nqal Partner Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            ></div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row"></div>
              <div class="text-center mt-md-5">
                <h3 class="mt-3">
                  {{ trader.name }}
                  <span class="font-weight-light"
                    >, {{ "#" + trader.id }}
                  </span>
                </h3>
                <div class="h5 font-weight-300">
                  <i class="ni location_pin mr-2"></i>{{ trader.email }}
                </div>
                <div class="h5 mt-4">
                  <i class="ni business_briefcase-24 mr-2"></i
                  >{{ trader.phone }}
                </div>
                <hr class="my-4" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ trader.name }}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="#!" class="btn btn-sm btn-primary"
                      >Orders: {{ trader.orders_cnt }}</a
                    >
                  </div>
                </div>
              </div>
            </template>
            <!-- Working Timae Table -->
            <h6 class="heading-small text-muted mb-4">Working Days</h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-6">
                  <h3>
                    From
                    <h4>{{ trader.day_from }}</h4>
                  </h3>
                </div>
                <div class="col-6">
                  <h3>
                    To
                    <h4>{{ trader.day_to }}</h4>
                  </h3>
                </div>
              </div>
            </div>
            <hr class="my-4" />
            <h6 class="heading-small text-muted mb-4">Working Time</h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-6">
                  <h3>
                    From
                    <h4>{{ trader.time_from }}</h4>
                  </h3>
                </div>
                <div class="col-6">
                  <h3>
                    To
                    <h4>{{ trader.time_to }}</h4>
                  </h3>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "trader-profile",
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      trader: "",
    };
  },
  created() {
    this.getCourier();
  },
  methods: {
    getCourier: function () {
      this.loading = true;
      axios
        .get("companies/traders/" + this.$route.params.id, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            this.trader = response.data.data[0];
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style></style>
