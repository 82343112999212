import axios from "axios";
import { messaging, getToken } from "../../firebase/firestoreDb";
export default {
  state: {
    nqalCompanyToken: false,
    nqalCompanyLoginData: false,
    error: "Null",
    avatar: false,
  },
  getters: {
    isLogged(state) {
      return !!state.nqalCompanyToken;
    },
    isError(state) {
      return state.error;
    },
    memberName(state) {
      return state.nqalCompanyLoginData.name;
    },
    memberAvatar(state) {
      return state.nqalCompanyLoginData.avatar;
    },
    company_logo(state) {
      return state.nqalCompanyLoginData.company_logo;
    },
    company_name(state) {
      return state.nqalCompanyLoginData.company_name;
    },
    company_phone(state) {
      return state.nqalCompanyLoginData.phone;
    },
  },
  mutations: {
    setNqalCompanyLoginData(state, nqalCompanyData) {
      state.nqalCompanyToken = nqalCompanyData.access_token;
      state.nqalCompanyLoginData = nqalCompanyData;
      localStorage.setItem(
        "nqalCompanyToken",
        JSON.stringify(nqalCompanyData.access_token)
      );
      localStorage.setItem(
        "nqalCompanyLoginData",
        JSON.stringify(nqalCompanyData)
      );
      // Used in Navbar Information
      // localStorage.setItem('nqalCompanyChangableData',JSON.stringify(nqalCompanyData));
      axios.defaults.headers.common.Authorization = `Bearer ${nqalCompanyData.access_token}`;
    },
    setLoginError(state, error) {
      state.error = error;
    },
    setLogout(state) {
      state.nqalCompanyToken = null;
      state.nqalCompanyLoginData = null;
      // localStorage.removeItem('nqalCompanyToken','nqalCompanyLoginData');
    },
    updateNqalCompanyLoginData(state, updatedData) {
      // state.nqalCompanyLoginData = updatedData;
      // localStorage.removeItem('nqalCompanyLoginData');
      localStorage.setItem("nqalCompanyLoginData", JSON.stringify(updatedData));
    },
    // Push Notification Firebase
    pushNotification() {
      getToken(messaging, {
        vapidKey:
          "BCnz0cmtKFofnOHYy3GuZyCUFs7sGEOmeI_kLndJeL-WNQ45t45LHs6gyUawnR_GKjW3FnDUZSwUwicDEBWT1jc",
      })
        .then((currentToken) => {
          if (currentToken) {
            axios
              .post("companies/updatefcmweb", {
                fcm_token_web: currentToken,
                _method: "put",
              })
              .then((response) => {
                if (response.data.error) {
                  //
                } else {
                  //
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally();
          } else {
            // Show permission request UI
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    },
  },
  actions: {
    Login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("companies/members/login", payload)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.status === 1) {
                commit("setNqalCompanyLoginData", res.data.data[0]);
                commit("pushNotification");
              } else {
                commit("setLoginError", res.data.message);
              }
            }
            resolve("/");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    Logout({ commit }) {
      return new Promise((resolve) => {
        axios.post("companies/members/logout").then((res) => {
          if (res.status === 200) {
            if (res.data.status === 1) {
              commit("setLogout");
              localStorage.removeItem("nqalCompanyToken");
              localStorage.removeItem("nqalCompanyLoginData");
              delete axios.defaults.headers.common["Authorization"];
            } else {
              //
            }
          }
          resolve("/login");
        });
      });
    },
    Update({ commit }, updatedData) {
      commit("setNqalCompanyLoginData", updatedData);
    },
  },
};
