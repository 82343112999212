<template>
  <base-loader v-if="loading"></base-loader>
  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-md-6">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col-md-6 text-right">
          <router-link :to="`/new-courier`" class="btn btn-primary btn-sm"
            ><i class="fas fa-plus"></i> {{ $t("couriers.new courier") }}
          </router-link>
          <select
            class="btn btn-primary btn-sm text-left"
            @change="getFilterdCouriers($event)"
          >
            <option value="">{{ $t("tables.filter by status") }}</option>
            <option value="all">{{ $t("tables.all") }}</option>
            <option
              v-for="(status, index) in statuses"
              :key="index"
              :value="status[0]"
            >
              {{ status[1] }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="couriers"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.email") }}</th>
          <th>{{ $t("tables.phone") }}</th>
          <th>{{ $t("tables.status") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <span class="avatar rounded-circle mr-3">
                <img :alt="row.item.name + 'Image'" :src="row.item.avatar" />
              </span>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.email }}
          </td>
          <td class="budget">
            {{ row.item.phone }}
          </td>
          <td class="budget">
            <span v-if="row.item.online" class="badge badge-dot">
              <i class="bg-success"></i> {{ $t("couriers.online") }}
            </span>
            <span v-else class="badge badge-dot">
              <i class="bg-warning"></i> {{ $t("couriers.offline") }}
            </span>
          </td>
          <td class="budget text-right">
            <select
              class="btn btn-success btn-sm text-left"
              @change="chgCourierStatus(row.item.id, $event)"
            >
              <option value="">{{ $t("tables.change status") }}</option>
              <option :value="1">{{ $t("couriers.enable") }}</option>
              <option :value="0">{{ $t("couriers.disable") }}</option>
            </select>
            <router-link
              :to="`/couriers/${row.item.id}`"
              class="btn btn-primary btn-sm"
              ><i class="fas fa-eye"></i
            ></router-link>
            <router-link
              :to="`/edit-courier/${row.item.id}`"
              class="btn btn-success btn-sm"
              ><i class="fas fa-edit"></i
            ></router-link>
          </td>
        </template>
      </base-table>
    </div>

    <div
      v-if="paginationFound"
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :pagination="pagination"
        :paginationLinks="paginationLinks"
        v-on:pagination-prev="getCouriers($event)"
        v-on:pagination-num="getCouriers(PaginationNum + $event)"
        v-on:pagination-next="getCouriers($event)"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
export default {
  name: "couriers-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      paginationFound: false,

      couriers: "",
      statuses: [
        ["available", `${this.$t("couriers.available")}`],
        ["busy", `${this.$t("couriers.busy")}`],
      ],
      targetStatus: "",

      pagination: "",
      paginationLinks: "",
      // currentPage: "",
      // For Pagination num
      PaginationNum: "",
    };
  },
  created() {
    this.getCouriers("companies/couriers/indexPaginate?page=1");
  },
  methods: {
    getCouriers: function ($pagination) {
      this.loading = true;
      if (this.targetStatus) {
        axios
          .post(
            $pagination,
            { status: this.targetStatus },
            {
              headers: {
                "Accept-Language": "en",
              },
            }
          )
          .then((response) => {
            if (response.data.error) {
              // console.log("Error");
            } else {
              this.couriers = response.data.data;
              this.pagination = response.data.meta;
              this.paginationLinks = response.data.links;
              this.PaginationNum = "companies/couriers/search?page=";
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                // console.log(error.response.headers);
              } else if (error.request) {
                // console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
            } else {
              this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      } else {
        //If Not Filtering Couriers
        this.paginationFound = true;
        axios
          .get($pagination, {
            headers: {
              "Accept-Language": "en",
            },
          })
          .then((response) => {
            if (response.data.error) {
              // console.log("Error");
            } else {
              this.couriers = response.data.data;
              this.pagination = response.data.meta;
              this.paginationLinks = response.data.links;
              this.PaginationNum = "companies/couriers/indexPaginate?page=";
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                // console.log(error.response.headers);
              } else if (error.request) {
                // console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
            } else {
              this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    // Fiter Couriers
    getFilterdCouriers: function () {
      // If choose 'all' in Filterd Box
      if (event.target.value == "all") {
        this.targetStatus = "";
        this.getCouriers("companies/couriers/indexPaginate?page=1");
      } else {
        this.paginationFound = false;
        this.loading = true;
        this.targetStatus = event.target.value;
        const headers = {
          "Accept-Language": "ar",
        };
        axios
          .post(
            "companies/couriers/courierstatus",
            { status: this.targetStatus },
            { headers: headers }
          )
          .then((response) => {
            if (response.data.error) {
              // console.log("Error");
            } else {
              this.couriers = response.data.data;
              console.log(this.couriers);
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                // console.log(error.response.headers);
              } else if (error.request) {
                // console.log(error.request);
              } else {
                // console.log('Error', error.message);
              }
            } else {
              this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    // Change Order Status
    chgCourierStatus: function ($courier_id) {
      this.loading = true;
      this.newStatus = event.target.value;
      const headers = {
        "Accept-Language": "ar",
      };
      axios
        .post(
          "companies/couriers/update",
          { _method: "put", id: $courier_id, enable: this.newStatus },
          { headers: headers }
        )
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = [response.data.message];
            return createToast(response.data.message, {
              type: "danger",
              timeout: 4000,
            });
          } else {
            if (response.data.status == 0) {
              return createToast(response.data.message, {
                type: "danger",
                timeout: 4000,
              });
            } else {
              // console.log(this.pagination.path+'?page='+this.pagination.current_page);
              return createToast("Updated Successfully", {
                type: "success",
                timeout: 4000,
              });
              // this.getCouriers(this.pagination.path+'?page='+this.pagination.current_page);
              // return createToast(response.data.message,
              // {
              // type: 'success',
              // timeout: 3000
              // });
            }
            // this.orders = response.data.data;
            // this.pagination = response.data.meta;
            // this.paginationLinks = response.data.links;
            // this.PaginationNum = 'companies/orders/search?page=';
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
