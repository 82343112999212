<template>
  <base-loader v-if="loading"></base-loader>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form" class="login-form">
            <div
              class="bg-danger text-white pl-3 mx-4 mt-2 py-1 live-error text-center"
              v-if="error"
            >
              {{ error }}
            </div>
            <div v-if="forgetPage">
              <base-input
                formClasses="input-group-alternative mb-3"
                :placeholder="$t('form.email')"
                type="email"
                addon-left-icon="ni ni-email-83"
                v-model="email"
              >
              </base-input>
              <div
                v-show="emailValidationErr"
                class="live-error bg-danger text-white mb-2"
              >
                {{ $t("validation.email not valid") }}
              </div>
              <div class="text-center">
                <base-button
                  type="primary"
                  class="my-4"
                  :disabled="!isValidForm"
                  @click.prevent="getVerCode()"
                  >{{ $t("form.send verification code") }}</base-button
                >
              </div>
            </div>
            <div v-if="verifyPage">
              <base-input
                formClasses="input-group-alternative mb-3"
                :placeholder="$t('form.verification code')"
                type="text"
                addon-left-icon="ni ni-lock-circle-open"
                v-model="verification_code"
              >
              </base-input>
              <div class="d-flex align-items-center justify-content-between">
                <base-button
                  type="primary"
                  class="my-4"
                  :disabled="!isValidVerificationCode"
                  @click.prevent="verifyCode()"
                  >{{ $t("form.verify") }}</base-button
                >
                <base-button
                  type="primary"
                  class="my-4"
                  @click.prevent="resendCode()"
                  >{{ $t("form.resend") }}</base-button
                >
              </div>
            </div>
            <div v-if="resetPage">
              <base-input
                formClasses="input-group-alternative mb-3"
                :placeholder="$t('form.password')"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model="password"
              >
              </base-input>
              <base-input
                formClasses="input-group-alternative mb-3"
                :placeholder="$t('form.confirm password')"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model="password_confirmation"
              >
              </base-input>
              <div class="text-center">
                <base-button
                  type="primary"
                  class="my-4"
                  @click.prevent="updatePassword()"
                  >{{ $t("form.update password") }}</base-button
                >
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link :to="`/login`" class="text-light"
            ><small>{{
              $t("login.sign in with credentials")
            }}</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
export default {
  name: "forget_password",
  data() {
    return {
      loading: false,
      email: "",
      verification_code: "",
      passwoed: "",
      password_confirmation: "",
      forgetPage: true,
      verifyPage: false,
      resetPage: false,
      verficationSuccess: false,
      error: false,
      success: false,
    };
  },
  computed: {
    emailValidationErr() {
      return (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email
        ) && this.email.length > 0
      );
    },
    // passwordValidationErr() {
    //   return this.password.length > 0 && this.password.length < 6;
    // },
    isValidForm() {
      return this.email.length > 0;
    },
    isValidVerificationCode() {
      return this.verification_code.length > 0;
    },
  },
  methods: {
    // Request Verification Code
    getVerCode: function () {
      if (this.loading == false) {
        this.loading = true;
        axios
          .post("https://nqal.co/testNqal/api/appsSettings/forgetPassword", {
            email: this.email,
          })
          .then((response) => {
            if (response.data.error) {
              return createToast({
                type: "danger",
                description: `${this.$t("validation.faild")}`,
                timeout: 4000,
              });
            } else {
              if (response.data.status == 0) {
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                this.forgetPage = false;
                this.verifyPage = true;
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 4000,
                });
              }
            }
          })
          .catch(() => {
            if (!window.navigator.onLine) {
              return createToast("يرجى التأكد من اتصالك بـ الانتر نت", {
                type: "danger",
                timeout: 4000,
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // Resend Verification Code
    resendCode: function () {
      if (this.loading == false) {
        this.loading = true;
        axios
          .post("https://nqal.co/testNqal/api/appsSettings/resend", {
            email: this.email,
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.error) {
              return createToast(response.data.message, {
                type: "danger",
                timeout: 4000,
              });
            } else {
              if (response.data.status == 0) {
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 4000,
                });
              }
            }
          })
          .catch(() => {
            if (!window.navigator.onLine) {
              return createToast("يرجى التأكد من اتصالك بـ الانتر نت", {
                type: "danger",
                timeout: 4000,
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // Verify Code
    verifyCode: function () {
      if (this.loading == false) {
        this.loading = true;
        axios
          .post("https://nqal.co/testNqal/api/appsSettings/verify-code", {
            verification_code: this.verification_code,
            email: this.email,
          })
          .then((response) => {
            if (response.data.error) {
              return createToast(response.data.message, {
                type: "danger",
                timeout: 4000,
              });
            } else {
              if (response.data.status == 0) {
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                this.verifyPage = false;
                this.resetPage = true;
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 4000,
                });
              }
            }
          })
          .catch(() => {
            if (!window.navigator.onLine) {
              return createToast("يرجى التأكد من اتصالك بـ الانتر نت", {
                type: "danger",
                timeout: 4000,
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // Verify Code
    updatePassword: function () {
      if (this.loading == false) {
        this.loading = true;
        axios
          .post("https://nqal.co/testNqal/api/appsSettings/resetPassword", {
            password: this.password,
            password_confirmation: this.password_confirmation,
            email: this.email,
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.error) {
              return createToast(response.data.message, {
                type: "danger",
                timeout: 4000,
              });
            } else {
              if (response.data.status == 0) {
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                setTimeout(() => {
                  this.$router.push("/login");
                }, 2200);
                new createToast(response.data.message, {
                  type: "success",
                  timeout: 2000,
                });
              }
            }
          })
          .catch(() => {
            if (!window.navigator.onLine) {
              return createToast("يرجى التأكد من اتصالك بـ الانتر نت", {
                type: "danger",
                timeout: 4000,
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
