<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <div v-if="errorMsg">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsg"
              :key="index"
            >
              {{ error }}
            </div>
          </div>
          <card v-else shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{ $t("sidebar.generate courier report") }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("reports.date information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-4">
                    <label class="form-control-label">
                      {{ $t("form.choose trader") }}
                    </label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="model.trader"
                    >
                      <option
                        v-for="(trader, index) in traders"
                        :key="index"
                        :value="trader.id"
                      >
                        {{ trader.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      :label="$t('global.from')"
                      :placeholder="$t('global.from')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.from_date"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      :label="$t('global.to')"
                      :placeholder="$t('global.to')"
                      input-classes="form-control-alternative"
                      type="date"
                      v-model="model.to_date"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('reports.generate report')"
                @click.prevent="sendDatestoGenerate"
              />
            </form>
          </card>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <traders-total-table
            type="dark"
            :title="$t('tables.total report')"
          ></traders-total-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import TradersTotalTable from "../../Tables/Reports/TradersTotalTable.vue";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "Generate-Traders-Report",
  components: {
    TradersTotalTable,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      traders: "",
      model: {
        from_date: "",
        to_date: "",
        trader: "",
      },
    };
  },
  created() {
    this.getTraders();
  },
  methods: {
    // Get All Traders
    getTraders: function () {
      this.loading = true;
      axios
        .get("companies/traders/all", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            this.traders = response.data.data;
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Send Date to get Report
    sendDatestoGenerate: function () {
      if (
        this.model.trader &&
        this.model.from_date.length > 0 &&
        this.model.to_date.length > 0
      ) {
        this.$router.push({
          name: "get traders report",
          params: {
            dates: [
              this.model.from_date,
              this.model.to_date,
              this.model.trader,
            ],
          },
        });
      } else {
        return createToast(`${this.$t("validation.all fields required")}`, {
          type: "danger",
          timeout: 4000,
        });
      }
    },
  },
};
</script>
