<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <div v-if="errorMsgLoading">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsgLoading"
              :key="index"
            >
              {{ error }}
            </div>
          </div>
          <card v-else shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">
                      {{ $t("sidebar.update working hours") }}
                    </h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("working hours.times of work") }}
              </h6>
              <div class="pl-lg-4">
                <div v-if="errorMsg">
                  <div
                    class="alert alert-danger text-center"
                    v-for="(error, index) in errorMsg"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("working hours.from day") }}
                      </label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="workingHours.day_from"
                      >
                        <option value="saturday">
                          {{ $t("working hours.saturday") }}
                        </option>
                        <option value="sunday">
                          {{ $t("working hours.sunday") }}
                        </option>
                        <option value="monday">
                          {{ $t("working hours.monday") }}
                        </option>
                        <option value="tuesday">
                          {{ $t("working hours.tuesday") }}
                        </option>
                        <option value="wednesday">
                          {{ $t("working hours.wednesday") }}
                        </option>
                        <option value="thursday">
                          {{ $t("working hours.thursday") }}
                        </option>
                        <option value="friday">
                          {{ $t("working hours.friday") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("working hours.to day") }}
                      </label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="workingHours.day_to"
                      >
                        <option value="saturday">
                          {{ $t("working hours.saturday") }}
                        </option>
                        <option value="sunday">
                          {{ $t("working hours.sunday") }}
                        </option>
                        <option value="monday">
                          {{ $t("working hours.monday") }}
                        </option>
                        <option value="tuesday">
                          {{ $t("working hours.tuesday") }}
                        </option>
                        <option value="wednesday">
                          {{ $t("working hours.wednesday") }}
                        </option>
                        <option value="thursday">
                          {{ $t("working hours.thursday") }}
                        </option>
                        <option value="friday">
                          {{ $t("working hours.friday") }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("working hours.from hour") }}
                      </label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="workingHours.time_from"
                      >
                        <option value="12:00 AM">
                          12:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="12:30 AM">
                          12:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="1:00 AM">
                          1:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="1:30 AM">
                          1:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="2:00 AM">
                          2:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="2:30 AM">
                          2:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="3:00 AM">
                          3:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="3:30 AM">
                          3:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="4:00 AM">
                          4:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="4:30 AM">
                          4:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="5:00 AM">
                          5:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="5:30 AM">
                          5:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="6:00 AM">
                          6:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="6:30 AM">
                          6:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="7:00 AM">
                          7:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="7:30 AM">
                          7:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="8:00 AM">
                          8:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="8:30 AM">
                          8:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="9:00 AM">
                          9:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="9:30 AM">
                          9:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="10:00 AM">
                          10:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="10:30 AM">
                          10:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="11:00 AM">
                          11:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="11:30 AM">
                          11:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="12:00 PM">
                          12:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="12:30 PM">
                          12:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="1:00 PM">
                          1:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="1:30 PM">
                          1:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="2:00 PM">
                          2:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="2:30 PM">
                          2:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="3:00 PM">
                          3:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="3:30 PM">
                          3:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="4:00 PM">
                          4:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="4:30 PM">
                          4:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="5:00 PM">
                          5:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="5:30 PM">
                          5:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="6:00 PM">
                          6:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="6:30 PM">
                          6:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="7:00 PM">
                          7:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="7:30 PM">
                          7:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="8:00 PM">
                          8:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="8:30 PM">
                          8:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="9:00 PM">
                          9:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="9:30 PM">
                          9:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="10:00 PM">
                          10:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="10:30 PM">
                          10:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="11:00 PM">
                          11:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="11:30 PM">
                          11:30 {{ $t("working hours.pm") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("working hours.to hour") }}
                      </label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="workingHours.time_to"
                      >
                        <option value="12:00 AM">
                          12:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="12:30 AM">
                          12:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="1:00 AM">
                          1:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="1:30 AM">
                          1:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="2:00 AM">
                          2:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="2:30 AM">
                          2:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="3:00 AM">
                          3:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="3:30 AM">
                          3:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="4:00 AM">
                          4:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="4:30 AM">
                          4:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="5:00 AM">
                          5:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="5:30 AM">
                          5:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="6:00 AM">
                          6:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="6:30 AM">
                          6:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="7:00 AM">
                          7:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="7:30 AM">
                          7:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="8:00 AM">
                          8:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="8:30 AM">
                          8:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="9:00 AM">
                          9:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="9:30 AM">
                          9:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="10:00 AM">
                          10:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="10:30 AM">
                          10:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="11:00 AM">
                          11:00 {{ $t("working hours.am") }}
                        </option>
                        <option value="11:30 AM">
                          11:30 {{ $t("working hours.am") }}
                        </option>
                        <option value="12:00 PM">
                          12:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="12:30 PM">
                          12:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="1:00 PM">
                          1:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="1:30 PM">
                          1:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="2:00 PM">
                          2:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="2:30 PM">
                          2:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="3:00 PM">
                          3:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="3:30 PM">
                          3:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="4:00 PM">
                          4:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="4:30 PM">
                          4:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="5:00 PM">
                          5:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="5:30 PM">
                          5:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="6:00 PM">
                          6:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="6:30 PM">
                          6:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="7:00 PM">
                          7:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="7:30 PM">
                          7:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="8:00 PM">
                          8:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="8:30 PM">
                          8:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="9:00 PM">
                          9:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="9:30 PM">
                          9:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="10:00 PM">
                          10:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="10:30 PM">
                          10:30 {{ $t("working hours.pm") }}
                        </option>
                        <option value="11:00 PM">
                          11:00 {{ $t("working hours.pm") }}
                        </option>
                        <option value="11:30 PM">
                          11:30 {{ $t("working hours.pm") }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <button
                class="btn btn-primary"
                @click.prevent="updateWorkingHours"
              >
                {{ $t("form.edit") }}
              </button>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "working-hours",

  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsgLoading: false,
      errorMsg: false,
      // Validation
      validationErrors: [],

      companyInfo: "",
      workingHours: { day_from: "", day_to: "", time_from: "", time_to: "" },
    };
  },
  created() {
    this.getCompanyInfo();
  },
  methods: {
    getCompanyInfo: function () {
      this.loading = true;
      axios
        .get("companies/show", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            this.companyInfo = response.data.data[0];
            this.workingHours.day_from = response.data.data[0]["day_from"];
            this.workingHours.day_to = response.data.data[0]["day_to"];
            this.workingHours.time_from = response.data.data[0]["time_from"];
            this.workingHours.time_to = response.data.data[0]["time_to"];
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              console.log(error.response.data);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          } else {
            this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Update Working Hours
    updateWorkingHours: function () {
      this.errorMsgLoading = "";
      this.errorMsg = "";
      this.loading = true;
      let formData = this.toFormDataEditing(this.workingHours);
      const headers = {
        "Accept-Language": "en",
      };
      axios
        .post("companies/workingHoursUpdate", formData, { headers: headers })
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.message;
          } else {
            if (response.data.status == 0) {
              console.log(response);
              this.errorMsg = [response.data.message];
              return createToast(response.data.message, {
                type: "danger",
                timeout: 3000,
              });
            } else {
              this.$router.push({
                name: "dashboard",
              });
              return createToast(response.data.message, {
                type: "success",
                timeout: 3000,
              });
            }
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },

    toFormDataEditing: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        if (obj[i] != "") {
          fd.append(i, obj[i]);
        }
      }
      return fd;
    },
  },
};
</script>
