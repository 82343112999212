<template>
  <base-loader v-if="loading"></base-loader>
  <!-- For Print Only -->
  <div class="print bg-white" id="printMe" v-if="shownInvoice">
    <div class="invoice-top-header p-4 d-flex justify-content-between">
      {{ $t("global.nqal") }}
      <div class="no-print">
        <button
          @click="printOrderInvoice()"
          class="btn btn-primary btn-sm print-btn"
        >
          <i class="fas fa-print"></i> {{ $t("orders.print invoice") }}
        </button>
        <button
          @click="shownInvoice = false"
          class="btn btn-primary btn-sm print-btn"
        >
          <i class="fas fa-long-arrow-alt-left"></i> {{ $t("global.back") }}
        </button>
      </div>
    </div>
    <header class="pb-0 invoice-header">
      <div class="container pt-4 pb-0 px-4">
        <div class="row">
          <div class="col-md-4 mb-4">
            <!-- <qrcode-vue :value="qrValue" :size="70" level="H" v-if="qrValue" /> -->

            <span class="date">#{{ orderInfo.id }}</span>
            <span
              class="date"
              v-for="(status, index) in orderInfo.statuses"
              :key="index"
            >
              <span v-if="index == orderInfo.statuses.length - 1">{{
                status.name
              }}</span>
            </span>
            <p>{{ $t("global.use invoice num") }}</p>
          </div>
          <div class="col-md-4 d-flex justify-content-md-center mb-4">
            <div>
              <h6>{{ $t("global.from") }}</h6>
              <span class="date">{{ orderInfo.address }}</span>
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-md-end mb-4">
            <div>
              <h6>{{ $t("global.to") }}</h6>
              <span class="date">{{ orderInfo.receiver_name }}</span>
              <span>{{ $t("tables.address") }}</span>
              <p class="mb-0">
                {{ orderInfo.state }} / {{ orderInfo.receiver_address }}
              </p>
              <p class="mb-0">
                {{ $t("tables.phone") }}: {{ orderInfo.receiver_phone }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="invoice-body mt-5 bg-white">
      <div class="container">
        <div class="table-responsive">
          <table class="table invoice-table">
            <thead>
              <tr class="border-0">
                <th>#</th>
                <th>{{ $t("form.name") }}</th>
                <th>{{ $t("auction.description") }}</th>
                <th>{{ $t("form.price") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in orderInfo.orderItems" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.value }}</td>
              </tr>
              <!-- <tr class="total-invoice">
                            <td colspan="2">SUPTOTAL<br><strong>100</strong></td>
                            <td colspan="2">TAX FEES (20%)<br><strong>20</strong></td>
                            <td colspan="3" class="last-td">TOTAL<br><strong>120</strong></td>
                        </tr> -->
            </tbody>
          </table>
        </div>
        <p class="mt-5">*{{ $t("global.use invoice num") }}</p>
      </div>
    </div>
    <footer class="mt-5 text-center py-2 invoice-footer bg-white">
      <p class="mb-0">{{ $t("global.thanks") }}</p>
      <div class="ar-ltr">
        <a href="#" class="mx-2"
          ><i class="fas fa-globe"></i> https://www.nqal.co</a
        >
        <!-- <a href="tel:01001010" class="mx-2"><i class="fas fa-phone"></i> 010101010</a> -->
        <a href="info@nqal.co" class="mx-2"
          ><i class="fas fa-envelope"></i> info@nqal.co</a
        >
      </div>
      <div v-if="orderInfo.order_serial">
        <vue3-barcode :value="orderInfo.order_serial" :height="50" />
      </div>
    </footer>
  </div>

  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>

  <div v-else>
    <div class="card shadow mb-4" v-if="shownInvoice == false">
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-default' : ''"
      >
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              {{ $t("orders.order details") }}
            </h3>
          </div>
          <div class="col text-right">
            <input
              :value="$t('orders.generate invoice')"
              type="button"
              @click="shownInvoice = true"
              class="btn btn-primary btn-sm print-btn"
            />
            <select
              class="btn btn-success btn-sm text-left"
              v-if="orderInfo.status_id == 1 && orderInfo.is_assigned == 0"
              @change="assignOrderToCourier($event)"
            >
              <option value="">{{ $t("orders.assign to courier") }}</option>
              <option
                v-for="(courier, index) in couriers"
                :key="index"
                :value="courier.id"
              >
                {{ courier.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table tablesorter table align-items-center table-flush">
          <!-- Table Head -->
          <tbody>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-circle-08 mr-2 text-main"></i>
                {{ $t("orders.address from") }}
              </th>
              <td>{{ orderInfo.address }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-circle-08 mr-2 text-main"></i>
                {{ $t("orders.receiver name") }}
              </th>
              <td>{{ orderInfo.receiver_name }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-mobile-button mr-2 text-main"></i>
                {{ $t("orders.receiver phone") }}
              </th>
              <td>{{ orderInfo.receiver_phone }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-square-pin mr-2 text-main"></i>
                {{ $t("orders.receiver address") }}
              </th>
              <td>
                {{ orderInfo.state + " - " + orderInfo.receiver_address }}
              </td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="fas fa-money-bill-wave-alt mr-2 text-main"></i>
                {{ $t("form.price") }}
              </th>
              <td>{{ orderInfo.price }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="fas fa-credit-card mr-2 text-main"></i>
                {{ $t("global.payment method") }}
              </th>
              <td>{{ orderInfo.payment_methods_name }}</td>
            </tr>
            <tr>
              <th class="d-flex align-items-center">
                <i class="ni ni-user-run mr-2 text-main"></i>
                {{ $t("tables.courier name") }}
              </th>
              <td>
                <span>{{ orderInfo.courier_name }}</span>
                <a
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    orderInfo.courier_lat +
                    ',' +
                    orderInfo.courier_long
                  "
                  target="_blank"
                  class="btn btn-success btn-sm mx-3"
                  v-if="orderInfo.courier_lat && orderInfo.courier_long"
                >
                  <i class="fas fa-map-marker-alt"></i>
                </a>
              </td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-delivery-fast mr-2 text-main"></i>
                {{ $t("orders.shipping status") }}
              </th>
              <td class="ar-ltr">
                <span
                  v-for="(status, index) in orderInfo.statuses"
                  :key="index"
                >
                  <span
                    class="badge text-white"
                    :class="
                      index != orderInfo.statuses.length - 1
                        ? 'bg-danger'
                        : 'bg-success'
                    "
                  >
                    {{ status.name }}
                  </span>
                  <i
                    v-if="index != orderInfo.statuses.length - 1"
                    class="fas fa-arrow-right text-main mx-2"
                  ></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Order Items -->
    <div
      class="card shadow"
      :class="type === 'dark' ? 'bg-default' : ''"
      v-if="shownInvoice == false"
    >
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              {{ title }}
            </h3>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <base-table
          class="table align-items-center table-flush"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="order"
        >
          <!-- Table Head -->
          <template v-slot:columns>
            <th>#</th>
            <th>{{ $t("global.image") }}</th>
            <th>{{ $t("form.name") }}</th>
            <th>{{ $t("auction.description") }}</th>
          </template>

          <!-- Table Body -->
          <template v-slot:default="row">
            <td class="budget">
              {{ row.item.id }}
            </td>
            <td class="budget">
              <img
                :src="'https://nqal.co/LiveNqal/public/' + row.item.image"
                :alt="row.item.title + ' Image'"
              />
            </td>
            <td class="budget">
              {{ row.item.title }}
            </td>
            <td class="budget">
              {{ row.item.description }}
            </td>
          </template>
        </base-table>
      </div>

      <div
        class="card-footer d-flex justify-content-end"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

// import QrcodeVue from "qrcode.vue";
import Vue3Barcode from "vue3-barcode";

// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
export default {
  name: "order-table",
  components: {
    // QrcodeVue,
    Vue3Barcode,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      shownInvoice: false,
      orderInfo: "",
      order: "",
      couriers: "",
      qrValue: "",
    };
  },
  created() {
    this.getOrder();
    this.getCouriers();
  },
  methods: {
    getOrder: function () {
      this.loading = true;
      axios
        .get("companies/orders/" + this.$route.params.id, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = ["Please Try again"];
          } else {
            this.orderInfo = response.data.data[0];
            this.qrValue = response.data.data[0].qr_code;
            this.order = response.data.data[0].orderItems;
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Couriers to assign
    getCouriers: function () {
      this.loading = true;
      axios
        .get("companies/couriers/index", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            this.couriers = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log(error.response);
          } else if (error.request) {
            // console.log(error.request);
          } else {
            // console.log('Error', error.message);
          }
        })
        .finally(() => (this.loading = false));
    },
    // Assign Order to Courier
    assignOrderToCourier: function () {
      this.loading = true;
      this.newStatus = event.target.value;
      const headers = {
        "Accept-Language": "ar",
      };
      axios
        .post(
          "companies/assign/courier",
          {
            _method: "put",
            order_id: this.orderInfo.id,
            courier_id: event.target.value,
          },
          { headers: headers }
        )
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = [response.data.message];
            return createToast(response.data.message, {
              type: "danger",
              timeout: 4000,
            });
          } else {
            if (response.data.status == 0) {
              return createToast(response.data.message, {
                type: "danger",
                timeout: 4000,
              });
            } else {
              this.getOrder();
              return createToast(response.data.message, {
                type: "success",
                timeout: 3000,
              });
            }
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Print Invoice
    printOrderInvoice() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printMe").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      //   WinPrint.close();
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100px;
  height: 80px;
  object-fit: contain;
}
.invoice-top-header {
  background-color: #3a3d50;
  color: #fff;
  font-size: 20px;
}
.invoice-header > .container {
  background-color: #f7f7f7;
}
.invoice-header .row > div {
  width: 33%;
}
.invoice-header svg {
  font-size: 40px;
  margin-bottom: 10px;
}
.invoice-header h6 {
  color: #576677;
}
.invoice-header span {
  color: #576677;
  display: block;
}
.invoice-header span.date {
  font-weight: 600;
  font-size: 17px;
}
.invoice-header p {
  font-size: 500;
  color: #576677;
}
.invoice-table td,
.invoice-table th {
  vertical-align: middle;
  white-space: nowrap;
}
.invoice-body table img {
  max-width: 70px;
}
.invoice-body .total-invoice {
  background-color: #f7f7f7;
}
.invoice-body .total-invoice td.last-td {
  background-color: #3a3d50 !important;
  color: #fff;
  font-size: 22px;
}
.invoice-body > p {
  color: #555;
  font-weight: 600;
}
.invoice-footer {
  border-top: 1px solid #eee;
}
.invoice-footer a {
  color: #555;
}
.invoice-footer a svg {
  color: #576677;
}
</style>
