<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <div v-if="errorMsgLoading">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsgLoading"
              :key="index"
            >
              {{ error }}
            </div>
          </div>

          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("orders.new order") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <!-- Client Errors Handleing -->
              <div class="row" v-if="validationErrors.length">
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="bg-danger text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <!-- Server Errors Handleing -->
              <div v-if="errorMsg">
                <div
                  class="alert alert-danger text-center"
                  v-for="(error, index) in errorMsg"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("orders.receiver information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('orders.receiver name')"
                      :placeholder="$t('orders.receiver information')"
                      input-classes="form-control-alternative"
                      v-model="newOrder.receiver_name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('orders.receiver phone')"
                      :placeholder="$t('orders.receiver phone')"
                      input-classes="form-control-alternative"
                      v-model="newOrder.receiver_phone"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <base-input
                      alternative=""
                      :label="$t('orders.receiver address')"
                      :placeholder="$t('orders.receiver address')"
                      input-classes="form-control-alternative"
                      v-model="newOrder.receiver_address"
                    />
                  </div>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.additional information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-4">
                    <label class="form-control-label">
                      {{ $t("form.choose courier") }}
                    </label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="newOrder.courier_id"
                    >
                      <option
                        v-for="(courier, index) in couriers"
                        :key="index"
                        :value="courier.id"
                      >
                        {{ courier.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-control-label">
                      {{ $t("form.choose trader") }}
                    </label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="newOrder.trader_id"
                    >
                      <option
                        v-for="(trader, index) in traders"
                        :key="index"
                        :value="trader.id"
                      >
                        {{ trader.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      :label="$t('form.price')"
                      :placeholder="$t('form.price')"
                      input-classes="form-control-alternative"
                      v-model="newOrder.price"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.add')"
                @click.prevent="addOrder"
              />
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "new-order",
  data() {
    return {
      loading: false,
      errorMsgLoading: false,
      errorMsg: "",
      // Validation
      validationErrors: [],

      traders: "",
      couriers: "",
      newOrder: {
        receiver_name: "",
        receiver_phone: "",
        receiver_address: "",
        courier_id: "",
        trader_id: "",
        price: "",
      },
    };
  },
  created() {
    this.getTraders();
    this.getCouriers();
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (
        this.newOrder.receiver_name &&
        this.newOrder.receiver_phone &&
        this.newOrder.receiver_address &&
        this.newOrder.courier_id &&
        this.newOrder.price
      ) {
        return true;
      } else {
        if (!this.newOrder.receiver_name) {
          this.validationErrors.push(`${this.$t("validation.name required")}`);
        }
        if (!this.newOrder.receiver_phone) {
          this.validationErrors.push(`${this.$t("validation.phone required")}`);
        }
        if (!this.newOrder.receiver_address) {
          this.validationErrors.push(
            `${this.$t("validation.address required")}`
          );
        }
        if (!this.newOrder.courier_id) {
          this.validationErrors.push(
            `${this.$t("validation.courier required")}`
          );
        }
        if (!this.newOrder.price) {
          this.validationErrors.push(`${this.$t("validation.price required")}`);
        }
        return false;
      }
    },
    // Get All Traders
    getTraders: function () {
      this.loading = true;
      this.errorMsgLoading = "";
      axios
        .get("companies/traders/all", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            //
          } else {
            this.traders = response.data.data;
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              //
            } else if (error.request) {
              //
            } else {
              //
            }
          } else {
            this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get All Couriers
    getCouriers: function () {
      this.loading = true;
      axios
        .get("companies/couriers/index", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            //
          } else {
            this.couriers = response.data.data;
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              //
            } else if (error.request) {
              //
            } else {
              //
            }
          } else {
            //
          }
        })
        .finally(() => (this.loading = false));
    },
    // Add Order
    addOrder: function () {
      if (this.validation() == false) {
        return false;
      } else {
        this.errorMsgLoading = "";
        this.errorMsg = "";
        this.loading = true;
        let formData = this.toFormData(this.newOrder);
        const headers = {
          "Accept-Language": "en",
        };
        axios
          .post("companies/orders/companyOrder", formData, { headers: headers })
          .then((response) => {
            if (response.data.error) {
              this.errorMsg = response.data.message;
            } else {
              if (response.data.status == 0) {
                this.errorMsg = [response.data.message];
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                this.$router.push({
                  name: "orders",
                });
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 3000,
                });
              }
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                //
              } else if (error.request) {
                //
              } else {
                //
              }
            } else {
              this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    toFormData: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },
};
</script>
