<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="min-height: 300px"
    >
      <!-- Mask -->
      <span class="mask bg-gradient-main opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row w-100">
          <div class="col-lg-12">
            <h1 class="display-2 text-white">
              {{ $t("sidebar.welcome") }} {{ get_member_name }}
            </h1>
            <a href="#!" class="btn btn-info">
              {{ $t("sidebar.update profile") }}
            </a>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>

          <div v-if="errorMsgLoading">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsgLoading"
              :key="index"
            >
              {{ error }}
            </div>
          </div>

          <card v-else shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("profile.my account") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("profile.personal information") }}
              </h6>
              <div class="pl-lg-4">
                <!-- Client Errors Handleing -->
                <div class="row" v-if="validationErrors.length">
                  <div
                    class="col-md-6"
                    v-for="(error, index) in validationErrors"
                    :key="index"
                  >
                    <span class="bg-danger text-white mr-2 error-handling">{{
                      error
                    }}</span>
                  </div>
                </div>
                <!-- Server Errors Handleing -->
                <div class="col-lg-12" v-if="errorMsg">
                  <div
                    class="alert alert-danger text-center"
                    v-for="(error, index) in errorMsg"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("form.name") }}
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        v-model="profileInfo.name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("form.email") }}
                      </label>
                      <input
                        type="email"
                        class="form-control form-control-alternative"
                        v-model="profileInfo.email"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("form.phone") }}
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        v-model="profileInfo.phone"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("profile.image") }}
                      </label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="updateImage"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("profile.current password") }}
                      </label>
                      <input
                        type="password"
                        class="form-control form-control-alternative"
                        v-model="profileInfo.current_password"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" @click.prevent="updateProfile">
                {{ $t("form.edit") }}
              </button>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "update-profile",
  data() {
    return {
      loading: false,
      errorMsg: false,
      errorMsgLoading: false,
      // Validation
      validationErrors: [],

      profileInfo: "",
      updatedImage: "",
    };
  },
  computed: {
    get_member_name() {
      return store.getters.memberName;
    },
  },
  created() {
    if (window.navigator.onLine) {
      this.errorMsgLoading = false;
      this.profileInfo = JSON.parse(
        localStorage.getItem("nqalCompanyLoginData")
      );
    } else {
      this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
    }
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (
        this.profileInfo.name &&
        this.profileInfo.phone &&
        this.profileInfo.current_password
      ) {
        return true;
      } else {
        if (!this.profileInfo.name) {
          this.validationErrors.push(`${this.$t("validation.name required")}`);
        }
        if (!this.profileInfo.phone) {
          this.validationErrors.push(`${this.$t("validation.phone required")}`);
        }
        if (!this.profileInfo.current_password) {
          this.validationErrors.push(
            `${this.$t("validation.password required")}`
          );
        }
        return false;
      }
    },
    updateProfile: function () {
      if (this.validation() == false) {
        return false;
      } else {
        this.errorMsgLoading = "";
        this.errorMsg = "";
        this.loading = true;
        let formData = this.toFormData();
        axios
          .post("companies/members/update", formData, {
            headers: {
              "Accept-Language": "en",
            },
          })
          .then((response) => {
            if (response.data.error) {
              //
            } else {
              if (response.data.status == 0) {
                this.errorMsg = [response.data.message];
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                response.data.data[0].access_token = JSON.parse(
                  localStorage.getItem("nqalCompanyLoginData")
                ).access_token;
                response.data.data[0].company_logo = JSON.parse(
                  localStorage.getItem("nqalCompanyLoginData")
                ).company_logo;
                this.updateLocalStorage(response.data.data[0]);
                this.$router.push({
                  name: "dashboard",
                });
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 3000,
                });
              }
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                //
              } else if (error.request) {
                //
              } else {
                //
              }
            } else {
              this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    updateLocalStorage(data) {
      this.$store.dispatch("Update", data);
    },
    // Get Image to Update
    updateImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.updatedImage = files[0];
    },
    toFormData: function () {
      var fd = new FormData();
      fd.append("_method", "put");
      fd.append("name", this.profileInfo.name);
      fd.append("phone", this.profileInfo.phone);
      fd.append("current_password", this.profileInfo.current_password);
      if (this.updatedImage) {
        fd.append("avatar", this.updatedImage);
      }
      return fd;
    },
  },
};
</script>
<style></style>
