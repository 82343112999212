<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <div v-if="errorMsgLoading">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsgLoading"
              :key="index"
            >
              {{ error }}
            </div>
          </div>

          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("auction.set price") }}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <router-link
                      :to="`/coming-offer-auction`"
                      class="btn btn-primary btn-sm"
                      ><i class="fas fa-long-arrow-alt-left"></i>
                      {{ $t("global.back") }}</router-link
                    >
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("auction.set your price") }}
              </h6>
              <div class="pl-lg-4">
                <!-- Client Errors Handleing -->
                <div class="row" v-if="validationErrors.length">
                  <div
                    class="col-md-6"
                    v-for="(error, index) in validationErrors"
                    :key="index"
                  >
                    <span class="bg-danger text-white mr-2 error-handling">{{
                      error
                    }}</span>
                  </div>
                </div>
                <!-- Server Errors Handleing -->
                <div v-if="errorMsg">
                  <div
                    class="alert alert-danger text-center"
                    v-for="(error, index) in errorMsg"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('auction.price')"
                      :placeholder="$t('auction.price')"
                      input-classes="form-control-alternative"
                      v-model="price"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.send')"
                @click.prevent="setPrice"
              />
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "set-price",
  data() {
    return {
      loading: false,
      errorMsgLoading: false,
      errorMsg: "",
      // Validation
      validationErrors: [],

      price: "",
    };
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      var englishNum = /^[0-9]*$/;
      if (this.price && englishNum.test(this.price)) {
        return true;
        // if (this.price)
      } else {
        if (!this.price) {
          this.validationErrors.push(`${this.$t("validation.price required")}`);
        }
        if (!englishNum.test(this.price)) {
          this.validationErrors.push(
            `${this.$t("validation.english numbers only")}`
          );
        }
        return false;
      }
    },
    // Set Price
    setPrice: function () {
      if (this.validation() == false) {
        return false;
      } else {
        this.errorMsgLoading = "";
        this.errorMsg = "";
        this.loading = true;
        const headers = {
          "Accept-Language": "en",
        };
        axios
          .post(
            "companies/requestedorders/setprice",
            { orderRequest_id: this.$route.params.id, price: this.price },
            { headers: headers }
          )
          .then((response) => {
            if (response.data.error) {
              console.log(response);
              this.errorMsg = response.data.message;
            } else {
              if (response.data.status == 0) {
                this.errorMsg = [response.data.message];
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                this.$router.push({
                  name: "coming offer auction",
                });
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 3000,
                });
              }
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                //
              } else if (error.request) {
                //
              } else {
                //
              }
            } else {
              this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
