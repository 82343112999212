<template>
  <ul class="pagination justify-content-center flex-wrap">
    <li
      class="page-item prev-page"
      :class="paginationLinks.prev == null ? 'disabled' : ''"
    >
      <a
        class="page-link"
        @click="$emit('pagination-prev', paginationLinks.prev)"
        ><i class="fas fa-angle-left"></i
      ></a>
    </li>
    <li
      class="page-item"
      v-for="(num, index) in pagination.last_page"
      :key="index"
      :class="pagination.current_page == num ? 'active' : ''"
    >
      <a class="page-link" @click="$emit('pagination-num', num)">{{ num }}</a>
    </li>
    <li
      class="page-item next-page"
      :class="paginationLinks.next == null ? 'disabled' : ''"
    >
      <a
        class="page-link"
        @click="$emit('pagination-next', paginationLinks.next)"
        ><i class="fas fa-angle-right"></i
      ></a>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["pagination", "paginationLinks"],
};
</script>
