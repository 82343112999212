<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      trader_logo: "",
      trader_name: "",
      loginStatus: "",
      mobile: null,
      mobileNav: true,
      windowWidth: null,
    };
  },
  mounted() {
    this.updateToken();
  },
  computed: {
    isLogged() {
      return this.$store.getters.isLogged;
    },
  },
  methods: {
    updateToken() {
      let nqalCompanyLoginData = JSON.parse(
        localStorage.getItem("nqalCompanyLoginData")
      );
      if (nqalCompanyLoginData != null) {
        this.$store.commit("setNqalCompanyLoginData", nqalCompanyLoginData);
      } else {
        //
      }
    },
    logout() {
      this.$store.dispatch("Logout").then((path) => {
        this.$router.push(path);
      });
    },
  },
};
</script>
