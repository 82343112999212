<template>
  <base-loader v-if="loading"></base-loader>
  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <router-link :to="`/new-trader`" class="btn btn-primary btn-sm"
            ><i class="fas fa-plus"></i>
            {{ $t("traders.new trader") }}</router-link
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="traders"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.orders count") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <span class="avatar rounded-circle mr-3">
                <img
                  :alt="row.item.name + 'Image'"
                  src="../../assets/trader.jpg"
                />
              </span>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.orders_cnt }}
          </td>
          <td class="budget text-right">
            <router-link
              :to="`/traders/${row.item.id}`"
              class="btn btn-primary btn-sm"
              ><i class="fas fa-eye"></i
            ></router-link>
            <router-link
              :to="`/edit-trader/${row.item.id}`"
              class="btn btn-success btn-sm"
              ><i class="fas fa-edit"></i
            ></router-link>
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :pagination="pagination"
        :paginationLinks="paginationLinks"
        v-on:pagination-prev="getTraders($event)"
        v-on:pagination-num="getTraders(PaginationNum + $event)"
        v-on:pagination-next="getTraders($event)"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "traders-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      traders: "",

      pagination: "",
      paginationLinks: "",
      // For Pagination num
      PaginationNum: "",
    };
  },
  created() {
    this.getTraders("companies/traders/index?page=1");
  },
  methods: {
    getTraders: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            //
          } else {
            this.traders = response.data.data;
            this.pagination = response.data.meta;
            this.paginationLinks = response.data.links;
            this.PaginationNum = "companies/traders/index?page=";
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              //
            } else if (error.request) {
              //
            } else {
              //
              //
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
