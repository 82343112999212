<template>
  <base-loader v-if="loading"></base-loader>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <div class="col-lg-4 col-md-6 mb-3 mb-lg-0">
          <div class="card card-1 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("orders.orders") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">{{
                  companyInfo.orders_cnt
                }}</span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni ni-sound-wave"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/orders"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>
                  {{ $t("orders.all orders") }}
                </span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3 mb-lg-0">
          <div class="card card-2 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("couriers.couriers") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">{{
                  companyInfo.couriers_cnt
                }}</span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni ni-sound-wave"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/couriers"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>{{ $t("couriers.all couriers") }}</span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 mb-3 mb-lg-0">
          <div class="card card-3 card-stats">
            <!-- Card body -->
            <div
              class="card-body d-flex align-items-center justify-content-between py-4 px-2"
            >
              <div>
                <h5 class="card-title text-uppercase mb-2">
                  {{ $t("traders.traders") }}
                </h5>
                <span class="h2 font-weight-bold mb-0">
                  {{ companyInfo.comapnyTrader_cnt }}
                </span>
              </div>
              <div class="icon icon-shape text-white rounded-circle shadow">
                <i class="ni ni-single-copy-04"></i>
              </div>
            </div>
            <div class="header-card-footer">
              <router-link
                to="/traders"
                class="d-flex align-items-center justify-content-between p-2"
              >
                <span>{{ $t("traders.all traders") }}</span>
                <i class="fas fa-angle-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="card shadow border-0 bg-default">
        <div class="card-header border-0 bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 text-white">{{ $t("couriers.couriers") }}</h3>
            </div>
          </div>
        </div>
        <div id="map" class="map-canvas" style="height: 600px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Loader } from "google-maps";
import "../plugins/map";
const loader = new Loader("AIzaSyDx-Roz0uBFP0w6YV2RMhYEwurSAVIH3IQ");

export default {
  data() {
    return {
      loading: "",
      companyInfo: "",
      couriers: "",
    };
  },
  created() {
    this.getCouriers();
    this.getCompanyInfo();
  },
  methods: {
    getCouriers: function () {
      this.loading = true;
      axios
        .get("companies/couriers/index", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            //
          } else {
            this.couriers = response.data.data;
            this.getMap(this.couriers);
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              //
            } else if (error.request) {
              //
            } else {
              //
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    getMap: function ($couriers) {
      // var map;
      // var overlay;
      loader.load().then(function (google) {
        function CustomMarker(latlng, imageSrc, map) {
          this.latlng_ = latlng;
          this.imageSrc = imageSrc;
          this.setMap(map);
        }

        CustomMarker.prototype = new google.maps.OverlayView();

        CustomMarker.prototype.draw = function () {
          var div = this.div_;
          if (!div) {
            div = this.div_ = document.createElement("div");
            div.className = "customMarker";
            div.style.cursor = "pointer";

            var img = document.createElement("img");
            img.src = this.imageSrc;
            div.appendChild(img);

            // Then add the overlay to the DOM
            var panes = this.getPanes();
            panes.overlayImage.appendChild(div);
          }

          // Position the overlay
          var point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
          if (point) {
            div.style.left = point.x + "px";
            div.style.top = point.y + "px";
          }
        };

        var opts = {
          zoom: 13,
          center: new google.maps.LatLng(24.2075, 55.74472),
          mapId: "2bf1cba222371325",
          scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
          disableDefaultUI: true, // a way to quickly hide all controls
          zoomControl: true,
        };
        // Get Couriers
        var markers = [];
        var order_id = "";
        for (var courier = 0; courier < $couriers.length; courier++) {
          if ($couriers[courier]["all_orders"][0]) {
            order_id = $couriers[courier]["all_orders"][0]["id"];
          } else {
            order_id = "";
          }
          if (
            $couriers[courier]["lat"] &&
            $couriers[courier]["lng"] &&
            ($couriers[courier]["online"] == 1 ||
              $couriers[courier]["busy"] == 1)
          ) {
            markers.push([
              $couriers[courier]["lat"],
              $couriers[courier]["lng"],
              $couriers[courier]["avatar"],
              $couriers[courier]["online"],
              $couriers[courier]["busy"],
              $couriers[courier]["name"],
              order_id,
            ]);
          }
        }
        const map = new google.maps.Map(document.getElementById("map"), opts);
        // Info Window
        var infoWindowContent = [];
        var infoWindow = new google.maps.InfoWindow();
        var order_id_window = "";
        for (var i = 0; i < markers.length; i++) {
          new CustomMarker(
            new google.maps.LatLng(markers[i][0], markers[i][1]),
            markers[i][2],
            map
          );
          // Marker Settings
          var position = new google.maps.LatLng(markers[i][0], markers[i][1]);
          var icon = {
            // url: markers[i][2],
            scaledSize: new google.maps.Size(50, 50), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(0, 0), // anchor
          };
          var marker = new google.maps.Marker({
            icon: icon,
            position: position,
            map: map,
          });
          if (markers[i][6]) {
            order_id_window = `<a href="orders/${markers[i][6]}" _target="blank">تفاصيل الطلب</a>`;
          } else {
            order_id_window = "";
          }

          // Courier Window Info
          infoWindowContent.push([
            `
              <div class="courier-info">
                <h4 class="courier-name">${markers[i][5]}</h4>
                ${order_id_window}
              </div>
            `,
          ]);
          // Add info window to marker
          google.maps.event.addListener(
            marker,
            "click",
            (function (marker, i) {
              return function () {
                infoWindow.setContent(infoWindowContent[i][0]);
                infoWindow.open(map, marker);
              };
            })(marker, i)
          );
        }
      });
    },
    getCompanyInfo: function () {
      this.loading = true;
      axios
        .get("companies/show", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            //
          } else {
            this.companyInfo = response.data.data[0];
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              //
            } else if (error.request) {
              //
            } else {
              //
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style>
.customMarker {
  position: absolute;
  cursor: pointer;
  background: #65d1d1;
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -70px;
  border-radius: 50%;
  padding: 0px;
}
.customMarker:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 19px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #65d1d1 transparent;
  display: block;
  width: 0;
}
.customMarker img {
  width: 50px;
  height: 50px;
  margin: 5px;
  border-radius: 50%;
}
.courier-info {
  padding: 10px 15px;
}
.courier-info .courier-name {
  color: #222;
  margin-bottom: 20px;
}
.courier-info a {
  background-color: #65d1d1;
  color: #fff;
  padding: 6px 14px;
  border-radius: 4px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
}
.courier-info a:hover {
  background-color: #005bea;
  color: #fff;
}
.card {
  box-shadow: -5px 6px 25px 0 #65a4d1;
  border: 0;
}
.header .card-1 {
  background-image: linear-gradient(
    to left,
    #0db2de 0%,
    #005bea 100%
  ) !important;
}
.header .card-2 {
  background-image: linear-gradient(
    to left,
    #ffc107 0%,
    #daa608 100%
  ) !important;
}
.header .card-3 {
  background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
}
.header .card-4 {
  background-image: linear-gradient(to left, #efa65f, #f76a2d) !important;
}
.header .card h5 {
  color: #fff;
  font-size: 12px;
}
.header .card-body span {
  color: #fff;
  font-size: 22px;
}
.header-card-footer {
  border-top: 1px solid #fff;
}
.header-card-footer a,
.header-card-footer a:hover {
  color: #fff;
}
.header-card-footer a span {
  font-size: 15px;
}
</style>
