import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store";

import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";

// Translation
import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import ar from "./locales/ar.json";
// import style_rtl from "./assets/style-rtl.css";

let userLang = "ar";
if (localStorage.getItem("userLang")) {
  userLang = localStorage.getItem("userLang");
} else {
  userLang = "ar";
}
if (userLang == "ar") {
  require("./assets/style-rtl.css");
} else {
  //
}
const language = {
  en: en,
  ar: ar,
};
const i18n = createI18n({
  locale: userLang,
  messages: language,
});

// dom.watch();

if (process.env.NODE_ENV == "development") {
  // Development
  axios.defaults.baseURL = "https://nqal.co/testNqal/api/company/";
  console.log("Development Version");
} else {
  // Development
  if (window.location.host == "testcompany.nqal.co") {
    axios.defaults.baseURL = "https://nqal.co/testNqal/api/company/";
    console.log("Development Version");
    // Production
  } else if (window.location.host == "company.nqal.co") {
    axios.defaults.baseURL = "https://nqal.co/LiveNqal/api/company/";
  } else {
    console.log(window.location.host);
    console.log("No API in this host");
  }
}
// import the library
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
// Check User Is Authorized To Access
axios.interceptors.response.use(
  (response) => {
    if (response.data["code"] && response.data["code"] == "405") {
      router.push({
        name: "dashboard",
      });
      return createToast(
        {
          title: response.data["message"],
        },
        {
          type: "danger",
          timeout: 3000,
        }
      );
    }
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("nqalCompanyToken");
      localStorage.removeItem("nqalCompanyLoginData");
      return router.push("/login");
    }
    return Promise.reject(error.response);
  }
);

// Check if Authorized before routes
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLogged && localStorage.getItem("nqalCompanyToken")) {
      next();
    } else {
      next({ name: "Login" });
    }
  }
  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (store.getters.isLogged) {
      next({ path: "/" });
    } else {
      next();
    }
  }
});

const app = createApp(App).use(store).use(router).use(ArgonDashboard).use(i18n);
app.config.globalProperties.axios = axios;
app.mount("#app");
