<template>
  <base-loader v-if="loading"></base-loader>
  <div v-if="errorMsg">
    <div
      class="alert alert-danger text-center"
      v-for="(error, index) in errorMsg"
      :key="index"
    >
      {{ error }}
    </div>
  </div>
  <div v-else class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="users"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.email") }}</th>
          <th>{{ $t("tables.phone") }}</th>
          <th>{{ $t("form.role") }}</th>
          <th>{{ $t("tables.action") }}</th>
          <th>{{ $t("tables.day from") }}</th>
          <th>{{ $t("tables.day to") }}</th>
          <th>{{ $t("tables.time from") }}</th>
          <th>{{ $t("tables.time to") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <span class="avatar rounded-circle mr-3">
                <img :alt="row.item.name + 'Image'" :src="row.item.avatar" />
              </span>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.email }}
          </td>
          <td class="budget">
            {{ row.item.phone }}
          </td>
          <td class="budget">
            {{ row.item.role }}
          </td>
          <td
            class="budget text-right"
            v-if="row.item.role == 'company_super_admin'"
          ></td>
          <td
            class="budget text-right"
            v-if="row.item.role && row.item.role != 'company_super_admin'"
          >
            <select
              class="btn btn-success btn-sm text-left"
              @change="updateAssignRole(row.item.id, $event)"
            >
              <option value="">{{ $t("form.choose role") }}</option>
              <option
                v-for="(role, index) in roles"
                :key="index"
                :value="role.name"
              >
                {{ role.name }}
              </option>
            </select>
          </td>
          <td class="budget text-right" v-if="!row.item.role">
            <select
              class="btn btn-success btn-sm text-left"
              @change="assignRole(row.item.id, $event)"
            >
              <option value="">{{ $t("form.choose role") }}</option>
              <option
                v-for="(role, index) in roles"
                :key="index"
                :value="role.name"
              >
                {{ role.name }}
              </option>
            </select>
          </td>
          <td class="budget">
            {{ row.item.day_from }}
          </td>
          <td class="budget">
            {{ row.item.day_to }}
          </td>
          <td class="budget">
            {{ row.item.time_from }}
          </td>
          <td class="budget">
            {{ row.item.time_to }}
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "users-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsg: false,
      users: "",
      roles: "",
      assigndRole: {
        member_id: "",
        role_name: "",
      },
      pagination: "",
      paginationLinks: "",
      // For Pagination num
      PaginationNum: "",
    };
  },
  created() {
    this.getUsers("companies/members/index?page=1");
    this.getRoles("companies/members/roles/index");
  },
  methods: {
    getUsers: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            this.users = response.data.data;
            this.pagination = response.data.meta;
            this.paginationLinks = response.data.links;
            this.PaginationNum = "companies/members/index?page=";
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.headers);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get All Roles
    getRoles: function ($url) {
      this.loading = true;
      axios
        .get($url, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            //
          } else {
            this.roles = response.data.data;
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              //
            } else if (error.request) {
              //
            } else {
              //
              //
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Assign Role
    assignRole: function ($user_id) {
      this.loading = true;
      this.assigndRole.member_id = $user_id;
      this.assigndRole.role_name = event.target.value;
      const headers = {
        "Accept-Language": "ar",
      };
      axios
        .post("companies/members/assignRole", this.assigndRole, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = [response.data.message];
            return createToast(response.data.message, {
              type: "danger",
              timeout: 4000,
            });
          } else {
            if (response.data.status == 0) {
              return createToast(response.data.message, {
                type: "danger",
                timeout: 4000,
              });
            } else {
              console.log(this.assigndRole.member_id);
              console.log(this.assigndRole.role_name);
              this.users.filter((user) => {
                if (user.id === this.assigndRole.member_id) {
                  console.log("Yes");
                  user.role = this.assigndRole.role_name;
                }
              });
              return createToast(response.data.message, {
                type: "success",
                timeout: 4000,
              });
            }
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Update User Role
    updateAssignRole: function ($user_id) {
      this.loading = true;
      this.assigndRole.member_id = $user_id;
      this.assigndRole.role_name = event.target.value;
      this.assigndRole._method = "put";
      const headers = {
        "Accept-Language": "ar",
      };
      axios
        .post("companies/members/updateAssignRole", this.assigndRole, {
          headers: headers,
        })
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = [response.data.message];
            return createToast(response.data.message, {
              type: "danger",
              timeout: 4000,
            });
          } else {
            if (response.data.status == 0) {
              return createToast(response.data.message, {
                type: "danger",
                timeout: 4000,
              });
            } else {
              console.log(this.assigndRole.member_id);
              console.log(this.assigndRole.role_name);
              this.users.filter((user) => {
                if (user.id === this.assigndRole.member_id) {
                  console.log("Yes");
                  user.role = this.assigndRole.role_name;
                }
              });
              return createToast(response.data.message, {
                type: "success",
                timeout: 4000,
              });
            }
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              // console.log(error.response.data);
            } else if (error.request) {
              // console.log(error.request);
            } else {
              // console.log('Error', error.message);
            }
          } else {
            this.errorMsg = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
