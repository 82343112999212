<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <roles-table type="dark" :title="$t('users.all roles')"></roles-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RolesTable from "../Tables/User/RolesTable.vue";
export default {
  name: "Roles",
  components: {
    RolesTable,
  },
};
</script>
<style></style>
