<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <div v-if="errorMsgLoading">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsgLoading"
              :key="index"
            >
              {{ error }}
            </div>
          </div>

          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("couriers.new courier") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("couriers.courier information") }}
              </h6>
              <div class="pl-lg-4">
                <!-- Client Errors Handleing -->
                <div class="row" v-if="validationErrors.length">
                  <div
                    class="col-md-6"
                    v-for="(error, index) in validationErrors"
                    :key="index"
                  >
                    <span class="bg-danger text-white mr-2 error-handling">{{
                      error
                    }}</span>
                  </div>
                </div>
                <!-- Server Errors Handleing -->
                <div v-if="errorMsg">
                  <div
                    class="alert alert-danger text-center"
                    v-for="(error, index) in errorMsg"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      input-classes="form-control-alternative"
                      v-model="newCourier.name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.email')"
                      placeholder="abc@example.com"
                      input-classes="form-control-alternative"
                      v-model="newCourier.email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.phone')"
                      :placeholder="$t('form.phone')"
                      input-classes="form-control-alternative"
                      v-model="newCourier.phone"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t("form.shipping type")
                    }}</label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="newCourier.shipping_type"
                    >
                      <option value="" selected>
                        {{ $t("form.choose shipping type") }}
                      </option>
                      <option
                        v-for="(shippingType, index) in shippingTypes"
                        :key="index"
                        :value="shippingType.id"
                      >
                        {{ shippingType.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Information -->
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.additional information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("couriers.license image")
                      }}</label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="uploadLicense"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("couriers.plate image")
                      }}</label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="uploadPlate"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("couriers.national id image")
                      }}</label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="uploadNationalId"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("couriers.personal image")
                      }}</label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="uploadAvatar"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="password"
                      alternative=""
                      :label="$t('form.password')"
                      :placeholder="$t('form.password')"
                      input-classes="form-control-alternative"
                      v-model="newCourier.password"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.add')"
                @click.prevent="addCourier"
              />
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "new-courier",
  data() {
    return {
      loading: false,
      errorMsgLoading: false,
      errorMsg: "",
      // Validation
      validationErrors: [],

      shippingTypes: "",

      uploadLicenseImage: "",
      uploadPlateImage: "",
      uploadNationalIdImage: "",
      uploadAvatarImage: "",
      newCourier: {
        name: "",
        email: "",
        phone: "",
        shipping_type: "",
        password: "",
        online: "0",
        enable: "0",
      },
    };
  },
  created() {
    this.getShippingTypes();
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (
        this.newCourier.name &&
        this.newCourier.email &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.newCourier.email
        ) &&
        this.newCourier.phone &&
        this.newCourier.shipping_type &&
        this.newCourier.password &&
        this.uploadLicenseImage &&
        this.uploadPlateImage &&
        this.uploadNationalIdImage &&
        this.uploadAvatarImage
      ) {
        return true;
      } else {
        if (!this.newCourier.name) {
          this.validationErrors.push(`${this.$t("validation.name required")}`);
        }
        if (!this.newCourier.email) {
          this.validationErrors.push(`${this.$t("validation.email required")}`);
        }
        if (
          this.newCourier.email &&
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.newCourier.email
          )
        ) {
          this.validationErrors.push(
            `${this.$t("validation.email not valid")}`
          );
        }
        if (!this.newCourier.phone) {
          this.validationErrors.push(`${this.$t("validation.phone required")}`);
        }
        if (!this.newCourier.shipping_type) {
          this.validationErrors.push(
            `${this.$t("validation.shipping type required")}`
          );
        }
        if (!this.uploadLicenseImage) {
          this.validationErrors.push(
            `${this.$t("validation.license image required")}`
          );
        }
        if (!this.uploadPlateImage) {
          this.validationErrors.push(
            `${this.$t("validation.plate image required")}`
          );
        }
        if (!this.uploadNationalIdImage) {
          this.validationErrors.push(
            `${this.$t("validation.id image required")}`
          );
        }
        if (!this.uploadAvatarImage) {
          this.validationErrors.push(
            `${this.$t("validation.personal image required")}`
          );
        }
        if (!this.newCourier.password) {
          this.validationErrors.push(
            `${this.$t("validation.password required")}`
          );
        }
        return false;
      }
    },
    // Get Shipping Types
    getShippingTypes: function () {
      this.loading = true;
      axios
        .get("common/shippingTypes", {
          headers: {
            "Accept-Language": localStorage.getItem("userLang")
              ? localStorage.getItem("userLang")
              : "ar",
          },
        })
        .then((response) => {
          if (response.data.error) {
            //
          } else {
            this.shippingTypes = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            //
          } else if (error.request) {
            //
          } else {
            //
          }
        })
        .finally(() => (this.loading = false));
    },

    // Add Courier
    addCourier: function () {
      if (this.validation() == false) {
        return false;
      } else {
        this.errorMsgLoading = "";
        this.errorMsg = "";
        this.loading = true;
        let formData = this.toFormData(this.newCourier);
        const headers = {
          "Accept-Language": "en",
        };
        axios
          .post("companies/couriers/register", formData, { headers: headers })
          .then((response) => {
            if (response.data.error) {
              this.errorMsg = response.data.message;
            } else {
              if (response.data.status == 0) {
                this.errorMsg = [response.data.message];
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                this.$router.push({
                  name: "couriers",
                });
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 3000,
                });
              }
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                //
              } else if (error.request) {
                //
              } else {
                //
              }
            } else {
              this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // Get Image to Add
    uploadLicense(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadLicenseImage = files[0];
    },
    uploadPlate(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadPlateImage = files[0];
    },
    uploadNationalId(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadNationalIdImage = files[0];
    },
    uploadAvatar(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadAvatarImage = files[0];
    },
    toFormData: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      fd.append("license_image", this.uploadLicenseImage);
      fd.append("plate_image", this.uploadPlateImage);
      fd.append("national_id", this.uploadNationalIdImage);
      fd.append("avatar", this.uploadAvatarImage);
      return fd;
    },
  },
};
</script>
