import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";

// Manage Users
import Users from "../views/User/Users.vue";
import NewUser from "../views/User/NewUser.vue";
import Roles from "../views/User/Roles.vue";
import AddRole from "../views/User/AddRole.vue";
import EditRole from "../views/User/EditRole.vue";

// Import Orders
import Orders from "../views/Order/Orders.vue";
import NewOrder from "../views/Order/NewOrder.vue";
import Order from "../views/Order/Order.vue";
import OrderTrack from "../views/Order/OrderTrack.vue";
import ComingOfferAuction from "../views/Order/ComingOfferAuction.vue";
import SetPrice from "../views/Order/SetPrice.vue";

// Import Courier
import Couriers from "../views/Courier/Couriers.vue";
import Courier from "../views/Courier/CourierProfile.vue";
import NewCourier from "../views/Courier/NewCourier.vue";
import EditCourier from "../views/Courier/EditCourier.vue";

// Import Trader
import Traders from "../views/Trader/Traders.vue";
import Trader from "../views/Trader/TraderProfile.vue";
import NewTrader from "../views/Trader/NewTrader.vue";
import EditTrader from "../views/Trader/EditTrader.vue";

// Import Profile Settings
import WorkingHours from "../views/Settings/WorkingHours.vue";
import Wallet from "../views/Wallet.vue";
import UpdateProfile from "../views/Settings/UpdateProfile.vue";

// Reports
// Companies Report
import GenerateCompaniesReport from "../views/Reports/Invoices/GenerateCompaniesReport.vue";
import GetCompaniesReport from "../views/Reports/Invoices/GetCompaniesReport.vue";
// Couriers Report
import GenerateCouriersReport from "../views/Reports/Invoices/GenerateCouriersReport.vue";
import GetCouriersReport from "../views/Reports/Invoices/GetCouriersReport.vue";
// Traders Report
import GenerateTradersReport from "../views/Reports/Invoices/GenerateTradersReport.vue";
import GetTradersReport from "../views/Reports/Invoices/GetTradersReport.vue";

import invoiceDesign from "../views/invoiceDesign.vue";

// Reviews
import CompanyReviews from "../views/Reviews/Company.vue";
// Customers
import CompanyCustomers from "../views/Customers/CompanyCustomers.vue";

import Icons from "../views/Icons.vue";
// import Maps from "../views/Maps.vue";
// import Profile from "../views/UserProfile.vue";
import Tables from "../views/Tables.vue";

import Map from "../views/Map.vue";

import Login from "../views/Login.vue";
import ForgetPassword from "../views/ForgetPassword.vue";

import Register from "../views/Register.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      // Start Manage Users
      {
        path: "/users",
        name: "users",
        components: { default: Users },
      },
      {
        path: "/new-user",
        name: "NewUser",
        components: { default: NewUser },
      },
      {
        path: "/add-role",
        name: "addRole",
        components: { default: AddRole },
      },
      {
        path: "/roles",
        name: "roles",
        components: { default: Roles },
      },
      {
        path: "/edit-role/:id",
        name: "Edit Role",
        components: { default: EditRole },
        props: true,
      },
      // End Manage Users

      {
        path: "/map",
        name: "Map",
        components: { default: Map },
      },

      // Orders Routes
      {
        path: "/orders",
        name: "orders",
        components: { default: Orders },
      },
      {
        path: "/create-order",
        name: "create order",
        components: { default: NewOrder },
      },
      {
        path: "/orders/:id",
        name: "order",
        components: { default: Order },
      },
      {
        path: "/orders/track/:id",
        name: "track order",
        components: { default: OrderTrack },
      },
      {
        path: "/coming-offer-auction",
        name: "coming offer auction",
        components: { default: ComingOfferAuction },
      },
      {
        path: "/set-price/:id",
        name: "set price",
        components: { default: SetPrice },
      },

      // Couriers
      {
        path: "/couriers",
        name: "couriers",
        components: { default: Couriers },
      },
      {
        path: "/couriers/:id",
        name: "courier",
        components: { default: Courier },
      },
      {
        path: "/new-courier",
        name: "New Courier",
        components: { default: NewCourier },
      },
      {
        path: "/edit-courier/:id",
        name: "Edit Courier",
        components: { default: EditCourier },
        props: true,
      },

      {
        path: "/traders",
        name: "traders",
        components: { default: Traders },
      },
      {
        path: "/traders/:id",
        name: "trader",
        components: { default: Trader },
      },
      {
        path: "/new-trader",
        name: "New Trader",
        components: { default: NewTrader },
      },
      {
        path: "/edit-trader/:id",
        name: "Edit Trader",
        components: { default: EditTrader },
        props: true,
      },

      {
        path: "/wallet",
        name: "wallet",
        components: { default: Wallet },
      },

      {
        path: "/update-profile",
        name: "update profile",
        components: { default: UpdateProfile },
      },

      {
        path: "/working-hours",
        name: "working hours",
        components: { default: WorkingHours },
      },

      // Reports
      // Companies Report
      {
        path: "/generate-companies-report",
        name: "generate companies report",
        components: { default: GenerateCompaniesReport },
        props: true,
      },
      {
        path: "/get-companies-report",
        name: "get companies report",
        components: { default: GetCompaniesReport },
        props: true,
      },
      // Couriers Report
      {
        path: "/generate-couriers-report",
        name: "generate couriers report",
        components: { default: GenerateCouriersReport },
        props: true,
      },
      {
        path: "/get-couriers-report",
        name: "get couriers report",
        components: { default: GetCouriersReport },
        props: true,
      },
      // Traders Report
      {
        path: "/generate-traders-report",
        name: "generate traders report",
        components: { default: GenerateTradersReport },
        props: true,
      },
      {
        path: "/get-traders-report",
        name: "get traders report",
        components: { default: GetTradersReport },
        props: true,
      },

      // Reviews
      {
        path: "/company-reviews",
        name: "company reviews",
        components: { default: CompanyReviews },
        props: true,
      },
      // Company Customers
      {
        path: "/company-customers",
        name: "company customers",
        components: { default: CompanyCustomers },
        props: true,
      },
      {
        path: "/invoice-design",
        name: "invoice design",
        components: { default: invoiceDesign },
        props: true,
      },

      {
        path: "/icons",
        name: "icons",
        components: { default: Icons },
      },
      // {
      //   path: "/maps",
      //   name: "maps",
      //   components: { default: Maps },
      // },
      // {
      //   path: "/profile",
      //   name: "profile",
      //   components: { default: Profile },
      // },
      {
        path: "/tables",
        name: "tables",
        components: { default: Tables },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    name: "Login",
    component: AuthLayout,
    meta: {
      hideForAuth: true,
    },
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/forget-password",
        name: "ForgetPassword",
        components: { default: ForgetPassword },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  linkActiveClass: "active",
  // routes,
  // mode: 'history'
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
