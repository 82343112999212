<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <div v-if="errorMsgLoading">
            <div
              class="alert alert-danger text-center"
              v-for="(error, index) in errorMsgLoading"
              :key="index"
            >
              {{ error }}
            </div>
          </div>
          <card v-else shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("couriers.edit courier") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("couriers.courier information") }}
              </h6>
              <div class="pl-lg-4">
                <!-- Client Errors Handleing -->
                <div class="row" v-if="validationErrors.length">
                  <div
                    class="col-md-6"
                    v-for="(error, index) in validationErrors"
                    :key="index"
                  >
                    <span class="bg-danger text-white mr-2 error-handling">{{
                      error
                    }}</span>
                  </div>
                </div>
                <!-- Server Errors Handleing -->
                <div v-if="errorMsg">
                  <div
                    class="alert alert-danger text-center"
                    v-for="(error, index) in errorMsg"
                    :key="index"
                  >
                    {{ error }}
                  </div>
                </div>

                <div class="row">
                  <base-input type="hidden" v-model="courierInfo.id" />
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      input-classes="form-control-alternative"
                      v-model="courierInfo.name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.email')"
                      placeholder="abc@example.com"
                      input-classes="form-control-alternative"
                      v-model="courierInfo.email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.phone')"
                      :placeholder="$t('form.phone')"
                      input-classes="form-control-alternative"
                      v-model="courierInfo.phone"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t("form.shipping type")
                    }}</label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="courierInfo.shipping_types_id"
                    >
                      <option value="">
                        {{ $t("form.choose shipping type") }}
                      </option>
                      <option
                        v-for="(shippingType, index) in shippingTypes"
                        :key="index"
                        :value="shippingType.id"
                        :selected="[
                          courierInfo.shipping_type ? selected : notSelected,
                        ]"
                      >
                        {{ shippingType.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <button class="btn btn-primary" @click.prevent="updateCourier">
                {{ $t("form.edit") }}
              </button>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import the library
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "edit-courier",
  props: ["couriers"],
  // props: {
  //     courierData: Object,
  // },

  data() {
    return {
      renderComponent: false,
      loading: false,
      errorMsgLoading: false,
      errorMsg: false,
      // Validation
      validationErrors: [],

      shippingTypes: "",
      courierInfo: {},
    };
  },
  created() {
    this.getShippingTypes();
    this.getCourier();
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (
        this.courierInfo.name &&
        this.courierInfo.email &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.courierInfo.email
        ) &&
        this.courierInfo.phone &&
        this.courierInfo.shipping_types_id
      ) {
        return true;
      } else {
        if (!this.courierInfo.name) {
          this.validationErrors.push(`${this.$t("validation.name required")}`);
        }
        if (!this.courierInfo.email) {
          this.validationErrors.push(`${this.$t("validation.email required")}`);
        }
        if (
          this.courierInfo.email &&
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.courierInfo.email
          )
        ) {
          this.validationErrors.push(
            `${this.$t("validation.email not valid")}`
          );
        }
        if (!this.courierInfo.phone) {
          this.validationErrors.push(`${this.$t("validation.phone required")}`);
        }
        if (!this.courierInfo.shipping_types_id) {
          this.validationErrors.push(
            `${this.$t("validation.shipping type required")}`
          );
        }
        return false;
      }
    },
    // Get Courier Information
    getCourier: function () {
      this.loading = true;
      axios
        .get("companies/couriers/" + this.$route.params.id, {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            // console.log("Error");
          } else {
            console.log(response.data.data[0]);
            this.courierInfo.id = response.data.data[0].id;
            this.courierInfo.name = response.data.data[0].name;
            this.courierInfo.email = response.data.data[0].email;
            this.courierInfo.phone = response.data.data[0].phone;
            this.courierInfo.shipping_types_id =
              response.data.data[0].shipping_types_id;
          }
        })
        .catch((error) => {
          if (window.navigator.onLine) {
            if (error.response) {
              //
            } else if (error.request) {
              //
            } else {
              //
            }
          } else {
            this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Shipping Types
    getShippingTypes: function () {
      this.loading = true;
      axios
        .get("common/shippingTypes", {
          headers: {
            "Accept-Language": "en",
          },
        })
        .then((response) => {
          if (response.data.error) {
            //
          } else {
            this.shippingTypes = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            //
          } else if (error.request) {
            //
          } else {
            //
          }
        })
        .finally(() => (this.loading = false));
    },

    // Update Courier
    updateCourier: function () {
      if (this.validation() == false) {
        return false;
      } else {
        this.errorMsgLoading = "";
        this.errorMsg = "";
        this.loading = true;
        let formData = this.toFormDataEditing(this.courierInfo);
        const headers = {
          "Access-Control-Allow-Methods": "PUT",
          "Access-Control-Allow-Headers": "Content-Type, Authorization",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json",
          "Accept-Language": "en",
        };
        axios
          .post("companies/couriers/update", formData, { headers: headers })
          .then((response) => {
            if (response.data.error) {
              this.errorMsg = response.data.message;
            } else {
              if (response.data.status == 0) {
                this.errorMsg = [response.data.message];
                return createToast(response.data.message, {
                  type: "danger",
                  timeout: 4000,
                });
              } else {
                this.$router.push({
                  name: "couriers",
                });
                return createToast(response.data.message, {
                  type: "success",
                  timeout: 3000,
                });
              }
            }
          })
          .catch((error) => {
            if (window.navigator.onLine) {
              if (error.response) {
                //
              } else if (error.request) {
                //
              } else {
                //
              }
            } else {
              this.errorMsgLoading = ["يرجى التأكد من اتصالك بـ الانتر نت"];
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    toFormDataEditing: function (obj) {
      var fd = new FormData();
      fd.append("_method", "put");
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },
};
</script>
