<template>
  <div>
    <base-header type="gradient-main" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <traders-table
            type="dark"
            :title="$t('traders.traders')"
          ></traders-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TradersTable from "../Tables/TradersTable.vue";
export default {
  name: "Traders",
  components: {
    TradersTable,
  },
};
</script>
<style></style>
